export default defineNuxtRouteMiddleware((to, from) => {

  // verify user session exists
  // note: if users try directly navigating to a page after having closed their browser,
  //       redirect them to the post-auth index, to initalize a new session.
  // note2: i used query params to redirect the users to the desired page,
  //        but depending on the destination, some data from the dashboard is required
  //        meaning we would need to head there regardless.
  const sessionUser = getSessionData('user')
  const sessionConfig = getSessionData('config')
  if (!sessionUser || !sessionConfig) {
    return navigateTo('/client')
  }

})

function getSessionData(key) {
  return JSON.parse(sessionStorage.getItem(key)) || null
}
